<template>
  <div>
    <FilterDashboard :farms="farms" @search="search" />

    <base-loading v-if="loading" />
    <div v-else>
      <v-row align="center" justify="center">
        <div v-if="dashboardReportData.percentege" class="chart-table">
          <h2
            class="text-xl-h4 font-weight-medium text-uppercase mt-4"
            style="color: #1b2565"
          >
            Inconformidades
          </h2>
          <v-row class="pb-4 mt-1" justify="center">
            <g-chart
              id="inconformidades"
              type="PieChart"
              style="height: 50vh; width: 100vh"
              :data="[
                ['Categoria', 'Quantidade'],
                [
                  'Ambiente de descanso dos animais com acúmulo de barro ( animais chegando sujos na ordenha )',
                  dashboardReportData.percentege.step9_1,
                ],
                [
                  'Não há identificação de casos de mastite clínica nas ordenhas (teste da caneca)',
                  dashboardReportData.percentege.step9_2,
                ],
                [
                  'Não realiza tratamento adequado de mastite',
                  dashboardReportData.percentege.step9_3,
                ],
                [
                  'Não faz os procedimentos básicos de ordenha (pré e pós dipping)',
                  dashboardReportData.percentege.step9_4,
                ],
                [
                  'Dieta desbalanceada (déficit nutricional)',
                  dashboardReportData.percentege.step9_5,
                ],
                [
                  'Animais com mastite crônica sem linha de ordenha',
                  dashboardReportData.percentege.step9_6,
                ],
                [
                  'Equipamento de ordenha desregulado ou sem manutenção a mais de 6 meses',
                  dashboardReportData.percentege.step9_7,
                ],
              ]"
              :options="chartOptions"
              :events="chartEvents"
            />
          </v-row>
        </div>
        <v-col cols="8" class="graficoConsultor">
          <h2 class="text-xl-h4 font-weight-medium" style="color: #1b2565">
            CONTROLE DE VISITAS
          </h2>
          <div class="chart-table">
            <table-graph :items="dataVisitsMade" :headers="headersVisitsMade" />
            <base-bar-graph :chart-data="chartDataVisitsMade" :style="styles" />
          </div>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="8" class="graficoConsultor">
          <h2 class="text-xl-h4 font-weight-medium" style="color: #1b2565">
            VOLUME DE LEITE (L/DIA) - ACOMPANHAMENTO MENSAL
          </h2>
          <div class="chart-table">
            <table-graph
              :items="dataAveregeProduction"
              :headers="headersVisitsMade"
            />
            <base-bar-graph
              :chart-data="chartDataAveregeProduction"
              :style="styles"
            />
          </div>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="8" class="graficoConsultor">
          <h2 class="text-xl-h4 font-weight-medium" style="color: #1b2565">
            CPP (CBT) - ACOMPANHAMENTO MENSAL
          </h2>
          <div class="chart-table">
            <table-graph :items="dataCPP" :headers="headersVisitsMade" />
            <base-bar-graph :chart-data="chartDataCPP" :style="styles" />
          </div>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="8" class="graficoConsultor">
          <h2 class="text-xl-h4 font-weight-medium" style="color: #1b2565">
            CCS - ACOMPANHAMENTO MENSAL
          </h2>
          <div class="chart-table">
            <table-graph :items="dataCCS" :headers="headersVisitsMade" />
            <base-bar-graph :chart-data="chartDataCSS" :style="styles" />
          </div>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="8" class="graficoConsultor">
          <h2 class="text-xl-h4 font-weight-medium" style="color: #1b2565">
            PREVALÊNCIA DE MASTITE CLÍNICA
          </h2>
          <div class="chart-table">
            <table-graph
              :items="dataClinicalMastitis"
              :headers="headersVisitsMade"
            />
            <base-bar-graph
              :chart-data="chartDataClinicalMastitis"
              :style="styles"
            />
          </div>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="8" class="graficoConsultor">
          <h2 class="text-xl-h4 font-weight-medium" style="color: #1b2565">
            TAXA DE MASTITE CRÔNICA (%)
          </h2>
          <div class="chart-table">
            <table-graph
              :items="dataChronicMastitis"
              :headers="headersVisitsMade"
            />
            <base-bar-graph
              :chart-data="chartDataChronicMastitis"
              :style="styles"
            />
          </div>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="8" class="graficoConsultor">
          <h2 class="text-xl-h4 font-weight-medium" style="color: #1b2565">
            TAXA DE CURA (%)
          </h2>
          <div class="chart-table">
            <table-graph :items="dataHealing" :headers="headersVisitsMade" />
            <base-bar-graph :chart-data="chartDataHealing" :style="styles" />
          </div>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="8" class="graficoConsultor">
          <h2 class="text-xl-h4 font-weight-medium" style="color: #1b2565">
            TAXA DE NOVAS INFECÇÕES (%)
          </h2>
          <div class="chart-table">
            <table-graph :items="dataInfections" :headers="headersVisitsMade" />
            <base-bar-graph :chart-data="chartDataInfections" :style="styles" />
          </div>
        </v-col>
      </v-row>
      <v-row cols="10" align="center" justify="center">
        <v-col cols="5" class="graficoConsultor">
          <h2 class="text-xl-h4 font-weight-medium" style="color: #1b2565">
            CCS Média (mil cels/ml)
          </h2>
          <div class="chart-table">
            <base-bar-graph
              :chart-data="chartWeightedAverageCCS"
              :style="styles"
            />
          </div>
        </v-col>

        <v-col cols="5" class="graficoConsultor">
          <h2 class="text-xl-h4 font-weight-medium" style="color: #1b2565">
            CPP Média (mil ufc/ml)
          </h2>
          <div class="chart-table">
            <base-bar-graph
              :chart-data="chartWeightedAverageCPP"
              :style="styles"
            />
          </div>
        </v-col>
      </v-row>

      <v-row align="center" justify="center">
        <v-col cols="10" class="graficoConsultor">
          <v-row cols="12">
            <v-col cols="6">
              <h2 class="text-xl-h4 font-weight-medium" style="color: #1b2565">
                (%) Por intervalo de CCS
              </h2>
              <div class="chart-table">
                <base-stacked-bar
                  :chart-data="chartDataProteinCels"
                  :style="styles"
                />
              </div>
            </v-col>
            <v-col cols="6">
              <h2 class="text-xl-h4 font-weight-medium" style="color: #1b2565">
                (%) Por intervalo de CPP
              </h2>
              <div class="chart-table">
                <base-stacked-bar
                  :chart-data="chartDataProteinCPP"
                  :style="styles"
                />
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row align="center" justify="center">
        <v-col cols="6" class="graficoConsultor">
          <h2 class="text-xl-h4 font-weight-medium" style="color: #1b2565">
            Média teor de gordura e proteina
          </h2>
          <div class="chart-table">
            <base-line-graph
              :chart-data="chartDataProteinAndFat"
              :style="styles"
              :width="400"
              :height="400"
            />
          </div>
        </v-col>
      </v-row>

      <DialogInconformidades
        v-if="inconformidadesList.length > 0"
        v-model="dialogInconformidades"
        :title="dialogTitle"
        :items="inconformidadesList"
        report-type="ccs"
      />
    </div>
  </div>
</template>

<script>
import _ from 'underscore'

import TableGraph from '../../../components/base/TableGraph.vue'
import {
  activeProducersList,
  assosiationLabel,
  monthShortLabel,
  renderDatasetGraph,
  renderDatasetTable,
} from '../../../utils/dashboard/dashboard'
import FilterDashboard from '../filter/index.vue'

import DialogInconformidades from '../../../components/reports/DialogInconformidades.vue'
import {
  avgProteinAndFat,
  proteinForIntervalCCS,
  proteinForIntervalCPP,
  weightedAverageCCS,
} from '../../../utils/dashboard/dashboardCCS'

export default {
  name: 'Dashboard',
  components: {
    TableGraph,
    FilterDashboard,
    DialogInconformidades,
  },

  emits: ['dashboard'],

  data() {
    return {
      activeProducersList,
      loading: true,
      farms: [],

      form: {
        produtor: '',
        consultor: '',
        agroindustria: '',
        fazenda: '',
        activeProducers: activeProducersList[0],
        dataInicio: this.moment()
          .subtract(11, 'months')
          .toISOString(true)
          .substr(0, 10),
        dataFim: this.moment().toISOString(true).substr(0, 10),
      },

      responsiveOptions: [
        [
          'screen and (max-width: 640px)',
          {
            seriesBarDistance: 5,
            axisX: {
              labelInterpolationFnc: function (value) {
                return value[0]
              },
            },
          },
        ],
      ],

      headersVisitsMade: [],
      dataVisitsMade: [],
      dataAveregeProduction: [],
      dataCPP: [],
      dataCCS: [],
      dataClinicalMastitis: [],
      dataChronicMastitis: [],
      dataHealing: [],
      dataInfections: [],

      styles: {
        backgroundColor: '#f1f1f1',
      },

      chartDataVisitsMade: {
        labels: monthShortLabel,
        datasets: [{ data: [0], label: '' }],
      },
      chartDataCPP: {
        labels: monthShortLabel,
        datasets: [{ data: [0], label: '' }],
      },
      chartDataCSS: {
        labels: monthShortLabel,
        datasets: [{ data: [0], label: '' }],
      },
      chartDataClinicalMastitis: {
        labels: monthShortLabel,
        datasets: [{ data: [0], label: '' }],
      },
      chartDataChronicMastitis: {
        labels: monthShortLabel,
        datasets: [{ data: [0], label: '' }],
      },
      chartDataHealing: {
        labels: monthShortLabel,
        datasets: [{ data: [0], label: '' }],
      },
      chartDataInfections: {
        labels: monthShortLabel,
        datasets: [{ data: [0], label: '' }],
      },
      chartDataAveregeProduction: {
        labels: monthShortLabel,
        datasets: [{ data: [0], label: '' }],
      },

      chartDataProteinCels: {
        labels: monthShortLabel,
        datasets: [{ data: [0], label: '' }],
      },

      chartDataProteinCPP: {
        labels: monthShortLabel,
        datasets: [{ data: [0], label: '' }],
      },

      chartDataProteinAndFat: {
        labels: monthShortLabel,
        datasets: [{ data: [0], label: '' }],
      },

      chartWeightedAverageCCS: {
        labels: monthShortLabel,
        datasets: [{ data: [0], label: '' }],
      },

      chartWeightedAverageCPP: {
        labels: monthShortLabel,
        datasets: [{ data: [0], label: '' }],
      },

      optionsCPP1: {
        seriesBarDistance: 10,
      },

      dialogInconformidades: false,
      inconformidadesList: [],
      dialogTitle: '',

      chartOptions: {
        legend: {},
        chartArea: { height: '85%', width: '100%' },
        tooltip: { isHtml: true },
      },

      chartEvents: {
        click: event => {
          const targetID = Number(event.targetID.split('#')[1])

          if (isNaN(targetID)) return

          this.inconformidadesMap(targetID)

          this.dialogInconformidades = true
        },
      },
    }
  },

  created() {
    this.dashboardReport()
  },

  methods: {
    renderDatasetGraph,
    renderDatasetTable,
    proteinForIntervalCCS,
    proteinForIntervalCPP,
    avgProteinAndFat,
    weightedAverageCCS,

    dashboardReport() {
      return new Promise((resolve, reject) => {
        this.loading = true

        this.api.get
          .getDashboardCCSVisit(
            this.form.dataInicio,
            this.form.dataFim,
            this.form.produtor,
            this.form.fazenda,
            this.form.agroindustria,
            this.form.consultor,
            2,
            this.form.activeProducers,
          )
          .then(async response => {
            this.dashboardReportData = response.data
            this.$emit('dashboard', response.data)

            await this.filter(this.dashboardReportData.mpufcCSS)

            const resultVisitsMade = _.groupBy(
              this.dashboardReportData.visitsMade,
              item => item.year,
            )

            this.headersVisitsMade = [
              { text: '', value: 'year' },
              ...Object.keys(resultVisitsMade).map(item => {
                return { text: item, value: item }
              }),
              ...assosiationLabel,
            ]

            const resultCPP = _.groupBy(
              this.dashboardReportData.averegeCPP,
              item => item.year,
            )

            const resultCSS = _.groupBy(
              this.dashboardReportData.averegeCSS,
              item => item.year,
            )

            const averegeProduction = _.groupBy(
              this.dashboardReportData.averegeProduction,
              item => item.year,
            )

            const averegeClinicalMastitis = _.groupBy(
              this.dashboardReportData.averegeClinicalMastitis,
              item => item.year,
            )

            const averegeChronicMastitis = _.groupBy(
              this.dashboardReportData.averegeChronicMastitis,
              item => item.year,
            )

            const averegeHealing = _.groupBy(
              this.dashboardReportData.averegeHealing,
              item => item.year,
            )

            const averegeInfections = _.groupBy(
              this.dashboardReportData.averegeInfections,
              item => item.year,
            )

            this.chartDataVisitsMade.datasets = this.renderDatasetGraph(
              resultVisitsMade,
            )
            const result = this.renderDatasetTable(
              this.chartDataVisitsMade.datasets,
            )

            const resultData = {}

            Object.entries(resultVisitsMade).forEach(([key, value]) => {
              let sum = 0
              value.forEach(item => {
                sum += item.avg
              })
              resultData[key] = sum
            })

            result.push({
              year: 'Realizado no ano',
              class: 'blue-row',
              ...resultData,
            })

            this.dataVisitsMade = result

            this.chartDataCPP.datasets = this.renderDatasetGraph(resultCPP)
            this.dataCPP = this.renderDatasetTable(this.chartDataCPP.datasets)

            this.dataAveregeProduction = this.renderDatasetTable(
              this.chartDataAveregeProduction.datasets,
            )

            this.chartDataCSS.datasets = this.renderDatasetGraph(resultCSS)
            this.dataCCS = this.renderDatasetTable(this.chartDataCSS.datasets)

            this.chartDataClinicalMastitis.datasets = this.renderDatasetGraph(
              averegeClinicalMastitis,
            )
            this.dataClinicalMastitis = this.renderDatasetTable(
              this.chartDataClinicalMastitis.datasets,
            )

            this.chartDataChronicMastitis.datasets = this.renderDatasetGraph(
              averegeChronicMastitis,
            )
            this.dataChronicMastitis = this.renderDatasetTable(
              this.chartDataChronicMastitis.datasets,
            )

            this.chartDataHealing.datasets = this.renderDatasetGraph(
              averegeHealing,
            )
            this.dataHealing = this.renderDatasetTable(
              this.chartDataHealing.datasets,
            )

            this.chartDataInfections.datasets = this.renderDatasetGraph(
              averegeInfections,
            )
            this.dataInfections = this.renderDatasetTable(
              this.chartDataInfections.datasets,
            )

            this.chartDataAveregeProduction.datasets = this.renderDatasetGraph(
              averegeProduction,
            )
            this.dataAveregeProduction = this.renderDatasetTable(
              this.chartDataAveregeProduction.datasets,
            )

            this.chartDataProteinCels = this.proteinForIntervalCCS(
              this.dashboardReportData.mpCelsCSS,
            )

            this.chartDataProteinCPP = this.proteinForIntervalCPP(
              this.dashboardReportData.mpufcCSS,
            )

            this.chartDataProteinAndFat = this.avgProteinAndFat(
              this.dashboardReportData.averegeProtein,
              this.dashboardReportData.averegeFat,
            )

            this.chartWeightedAverageCCS.datasets = this.weightedAverageCCS(
              this.dashboardReportData.mpCelsCSS,
              'ccsMedia',
            )

            this.chartWeightedAverageCPP.datasets = this.weightedAverageCCS(
              this.dashboardReportData.mpufcCSS,
              'cppMedia',
            )
            this.loading = false
            return resolve()
          })
          .catch(error => {
            return reject(error)
          })
      })
    },

    async filter(data) {
      const farms = [
        ...new Set(
          data?.map(item => {
            return item.farm
          }),
        ),
      ]

      this.farms = farms
    },

    async search(data) {
      this.form = data

      this.dashboardReport()
    },

    getDashboard() {
      this.loading = true
      this.dashboardReport().catch(() => {
        this.Swal.fire({
          title: 'Erro',
          text: 'Erro ao carregar dados',
          type: 'error',
        }).finally(() => {
          this.loading = false
        })
      })
    },

    inconformidadesMap(id) {
      const mapInconformidades = key =>
        this.dashboardReportData.percentegeData.filter(
          item => item[key] === '1',
        )

      const setInconformidadesListAndTitle = (list, title) => {
        this.inconformidadesList = list
        this.dialogTitle = title
      }

      switch (id) {
        case 0:
          setInconformidadesListAndTitle(
            mapInconformidades('step9_1'),
            'Ambiente de descanso dos animais com acúmulo de barro ( animais chegando sujos na ordenha )',
          )
          break

        case 1:
          setInconformidadesListAndTitle(
            mapInconformidades('step9_2'),
            'Não há identificação de casos de mastite clínica nas ordenhas (teste da caneca)',
          )
          break

        case 2:
          setInconformidadesListAndTitle(
            mapInconformidades('step9_3'),
            'Não realiza tratamento adequado de mastite',
          )
          break

        case 3:
          setInconformidadesListAndTitle(
            mapInconformidades('step9_4'),
            'Não faz os procedimentos básicos de ordenha (pré e pós dipping)',
          )
          break

        case 4:
          setInconformidadesListAndTitle(
            mapInconformidades('step9_5'),
            'Dieta desbalanceada (déficit nutricional)',
          )
          break

        case 5:
          setInconformidadesListAndTitle(
            mapInconformidades('step9_6'),
            'Animais com mastite crônica sem linha de ordenha',
          )
          break

        case 6:
          setInconformidadesListAndTitle(
            mapInconformidades('step9_7'),
            'Equipamento de ordenha desregulado ou sem manutenção a mais de 6 meses',
          )
          break

        default:
          break
      }
    },
  },
}
</script>

<style>
svg > g:last-child > g:last-child {
  pointer-events: none;
}
div.google-visualization-tooltip {
  pointer-events: none;
}

.v-data-table-header th {
  white-space: nowrap;
}
</style>
